<template src="./Home2d.html"></template>

<script>
import ModalDataUpdate from "../../components/ModalDataUpdate/ModalDataUpdate";
import institutionService from "../../services/Institution";
import { songs } from "@/utils/constants";
import ChangeHome from "../../components/ChangeHome/ChangeHome";
import tutorial from "@/mixins/tutorial";

export default {
  name: "Home2d",
  mixins: [tutorial],
  components: {
    ModalDataUpdate,
    ChangeHome,
  },
  data() {
    return {
      isMobile: false,
      isCardModalActive: false,
      openModalInvited: false,
      openModalDataUpdate: false,
      songs,
      role: "",
    };
  },
  mounted() {
    this.redirectToAdmin();
    this.role = this.$store.state.User.user.role.name;
    if (this.role === "Invited") {
      this.openModalInvited = true;
    } else {
      this.isCardModalActive = true;
    }
  },
  methods: {
    redirectToAdmin() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "Administrator") {
        this.$router.push("/AdminPanelAcademic");
      }
      if (role === "AdministratorCall") {
        this.$router.push("/administradorConvocatorias");
      }
    },
    closeModalDataUpdate() {
      this.openModalDataUpdate = false;
    },
    async sendDataToUpdate(infoUpdate) {
      try {
        for (let index = 0; index < infoUpdate.length; index++) {
          const element = infoUpdate[index];
          const obj = {
            usuario_id: this.$store.state.User.user.id,
            institucion_id: element,
          };
          await institutionService.universityApplication(obj);
        }
      } catch (error) {
        console.error(error);
      }
      this.openModalDataUpdate = false;
    },
    continueHome() {
      if (this.role === "Student" || this.role === "Teacher") {
        if (this.role === "Student") {
          this.dataTutorial("home2d");
        }
        if (!this.$store.state.User.user.institutionId) {
          this.openModalDataUpdate = true;
        }
      }
      if (this.role === "Invited") {
        this.dataTutorial("home2d");
      }
      this.isCardModalActive = false;
      this.openModalInvited = false;
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
  },
};
</script>

<style lang="scss" scoped src="./Home2d.scss"></style>